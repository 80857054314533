//******************************************************************************
// Live Chat.
//
// Chat displayed fixed to bottom-right corner of viewport.
//******************************************************************************

// Non-rendering library SASS.
@import "tools.vars";
@import "tools.mixins";

$chat-blue: #408fec;

//==============================================================================
// Chat Launcher.
//
// Clickable 'Chat Now?' call to action, used to bring up the live chat app.
//==============================================================================

.chat-launcher {
    // Chat launcher
    display: flex;
    align-items: center;
    padding: 10px;
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: $z-live-chat;
    background-color: $chat-blue;
    border-radius: 10px;
    color: white;
    cursor: pointer;
    box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.2);
    transition-property: box-shadow, bottom, background-color;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s, .5s, 0s;
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif;
    user-select: none;

    &:hover {
        // .chat-launcher:hover
        background-color: lighten($chat-blue, 2%);
        box-shadow: 0 3px 18px 0 rgba(0, 0, 0, 0.3);

        .chat-launcher__dot {
            // .chat-launcher:hover .chat-launcher__dot
            transform-origin: center;
            animation-duration: 0.8s;
            animation-timing-function: ease-in-out;
            animation-iteration-count: infinite;
            animation-play-state: running;
            animation-name: dot;
        }
    }

    @media (min-width: 850px) {
        bottom: 20px;
        right: 20px;
        padding: 20px;
        font-size: 20px;
    }

    &__icon {
        // .chat-launcher__icon
        // Chat bubble icon.
        width: 40px;
        margin-left: 20px;

        @media (min-width: 850px) {
            width: 60px;
        }
    }

    &__dot {
        // .chat-launcher__dot
        // Animated ellipsis dots.
        transform: scale(1) translateY(0px);
        animation-play-state: paused;

        &:nth-of-type(2) {
            // .chat-launcher__dot&:nth-of-type(2)
            animation-delay: 0.1s;
        }

        &:nth-of-type(3) {
            // .chat-launcher__dot&:nth-of-type(3)
            animation-delay: 0.2s;
        }
    }

    &.is-hidden {
        // .chat-launcher.is-hidden
        // Hidden state.
        bottom: -100px;
        transition-delay: 0s;
    }
}

@keyframes dot {
    // Elipsis dots wave animation.
    0%,
    100%,
    10%,
    90% {
        transform: scale(1) translateY(0px);
        opacity: 0.5;
    }

    50% {
        transform: scale(1.1) translateY(-3px);
        opacity: 1;
    }
}

//==============================================================================
// Chat App.
//
// Fixed-position container for the live chat app.
//==============================================================================

.chat-app {
    // Container for the live chat widget.
    display: flex;
    position: fixed;
    bottom: 0px;
    right: 0px;
    z-index: $z-live-chat;
    font-family: Arial, Helvetica, sans-serif;
    background-color: white;
    border-radius: 10px 10px 0 0;
    color: $grey-20;
    width: 100%;
    max-width: 370px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 6px, rgba(0, 0, 0, 0.2) 0px 2px 24px;
    transition-property: box-shadow, bottom, opacity;
    transition-duration: 500ms, 500ms, 250ms;
    transition-timing-function: ease-in-out;
    transition-delay: 200ms, 200ms, 400ms;

    &.is-hidden {
        // .chat-app.is-hidden
        // Hidden state.
        bottom: -800px;
        box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px;
        opacity: 0;
    }

    @media #{$desktop} {
        right: 20px;
        width: calc(100% - 40px);
    }

    //------------------------------------------------------------------------------
    // Chat buttons & form fields.
    //
    // Adjustments to the standard input fields and button styles.
    //-----

    .button {
        // .chat-app .button
        // Overrides standard Form UI library button styles.
        font-size: 17px;
        text-transform: none;
        letter-spacing: 0;
        font-variant: none;
        border-radius: 5px;

        &--primary {
            // .chat-app .button--primary
            background: $chat-blue;
            border: none;
            color: white;
        }
    }

    .field__input {
        // .chat-app .field__input
        // Overrides Form UI library field input styles.
        border-radius: 5px;
        font-size: 15px;
    }

    .field__label {
        // .chat-app .field__label
        // Overrides Form UI library field label styles.
        font-size: 15px;
    }
}


//==============================================================================
// Chat Dialog.
//==============================================================================

.chat-dialog {
    // Container used for the welcome form and main chat log content.
    flex: 1;
    display: flex;
    flex-direction: column;

    &__header {
        // .chat-dialog__header
        // Chat dialog header.
        display: flex;
        align-items: center;
        border-bottom: 1px solid $grey-87;
        padding: 0 10px;
        position: relative;
        height: 80px;

        @media #{$desktop} {
            padding: 0 20px;
        }
    }

    &__body {
        // .chat-dialog__body
        // Chat dialog body.
        display: flex;
        flex-direction: column;
        flex: 0 1 auto;
        position: relative;
    }

    &.is-hidden {
        // .chat-dialog.is-hidden
        // Chat dialog hidden state.
        display: none;
    }

    &.is-connected {
        // .chat-dialog.is-connected
        // 'Connected' state - showing agent's details in header.

        .agent-connecting {
            // .chat-dialog.is-connected .agent-connecting
            display: none;
        }

        .agent-info {
            // .chat-dialog.is-connected .agent-connecting .agent-info
            display: flex;
        }
    }


    &.is-finished {
        // 'Finished' state - hiding the chat log & showing the feedback form.

        .chat-log,
        .chat-reply-field {
            // .chat-dialog.is-finished .chat-log
            // .chat-dialog.is-finished .chat-reply-field
            display: none;
        }

        .feedback-form {
            // .chat-dialog.is-finished .feedback-form
            display: block;
        }
    }
}

//==============================================================================
// Chat Actions.
//
// Container for 'Minimise' & 'Close' buttons.
//==============================================================================

.chat-actions {
    // Container for minimise & close buttons to the right-hand side of header.
    display: flex;
    position: absolute;
    top: 0px;
    right: 18px;
    height: 100%;
    align-items: center;

    &__button {
        // .chat-actions__button
        // 'Minimise' & 'Close' buttons.
        display: flex;
        align-items: center;
        justify-content: center;
        -webkit-appearance: none;
        appearance: none;
        border: 0;
        width: 24px;
        height: 24px;
        margin: 0 0 0 5px;
        padding: 0;
        border-radius: 50%;
        cursor: pointer;
        border: 1px solid rgba($grey-98, 0.2);
        background: transparent;
        transition: background-color 0.2s ease-in-out;

        &:hover {
            // .chat-actions__button:hover
            background: rgba($grey-40, 0.05);

            .chat-icon use {
                // .chat-actions__button:hover .chat-icon use
                fill: $chat-blue;
            }
        }
    }
}

//==============================================================================
// SVG icons.
//==============================================================================

.chat-icon {
    // General styles for svg icons in the chat app.
    margin: auto;
    display: block;

    use {
        // .chat-icon use
        transition: fill 0.2s ease-in-out;
        fill: $grey-53;
    }
}

//==============================================================================
// Welcome Form.
//
//==============================================================================

.chat-welcome {
    // Welcome form.

    &__header {
        // Header containing 'Welcome to Live Chat' message.
        font-size: 20px;
    }

    &__body {
        // Centre-aligned form fields.
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 1;
        padding: 20px;
    }

    &__text-intro {
        // Text intro message.
        color: $grey-40;
        font-size: 15px;
        text-align: center;
        padding-top: 10px;
    }

    &__icon {
        // User icon.
        margin: 20px auto;
    }
}


//==============================================================================
// Chat Log & Chat Messages.
//
//==============================================================================

.agent-connecting {
    // Loading spinner in chta dialog header, shown while connecting to an agent.
    display: flex;
    align-items: center;

    &__text {
        // .agent-connecting__text
        // 'Waiting for an agent' text.
        padding: 0px 10px;
    }
}

.agent-info {
    // Agent's details shown in header once connected.
    display: none;

    &__avatar {
        // .agent-info__avatar
        // Avatar image for agent.
        height: 55px;
        width: 55px;

        > img {
            // .agent-info__avatar img
            height: 100%;
            width: 100%;
            border-radius: 50%;
        }
    }

    &__text {
        // .agent-info__text
        // Text container for agent name, status & role.
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0px 10px;
    }

    &__name {
        // .agent-info__name
        // Agent's name.
        display: inline-block;
        font-size: 1.1em;
        font-weight: bold;
    }

    &__status {
        // .agent-info__status
        // Agent's online/offline status.
        display: inline-block;
        margin: 0 0 0 3px;
        font-size: 0.85em;
        padding: 3px 6px;
        border-radius: 5px;
        position: relative;
        bottom: 1px;
        background: $grey-87;
        font-weight: normal;

        &.is-online {
            // .agent-info__status.is-online
            //  'Online' state.
            color: white;
            background: $color-positive;
        }
    }

    &__role {
        // .agent-info__role
        // Agent's role.
        margin: 0px;
        font-size: 0.9em;
        margin-top: 4px;
        color: gray;
    }
}

//------------------------------------------------------------------------------
// Chat Log.
//-----

.chat-log {
    // Scrollable container for chat messages.
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 0 1 350px;
    padding: 25px 20px 0 20px;
    position: relative;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scroll-behavior: smooth;
    text-align: center;

    &::-webkit-scrollbar {
        // .chat-log::-webkit-scrollbar
        display: none;
    }

    &__welcome-message {
        // .chat-log__welcome-message
        // Welsome message shown first in the chat log.
        color: $grey-53;
        font-size: 18px;
        text-align: center;
        padding: 0 25px 25px 25px;
        line-height: 1.3;
    }

    &--admin {
        // .chat-log--admin
        flex: 1 1 auto;
    }
}


//------------------------------------------------------------------------------
// Chat Message.
//-----

.chat-message {
    // Chat message displayed in a bubble.
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
    font-size: 16px;
    text-align: left;

    &:last-child {
        // .chat-message:last-child
        // Prevent bottom margin for last message.
        margin-bottom: 0;
    }

    &__bubble {
        // .chat-message__bubble
        // Message bubble containing message text.
        display: inline-flex;
        align-self: flex-start;
        flex-direction: column;
        padding: 15px;
        background: #edf0f5;
        border-radius: 20px 20px 20px 0px;
        margin: 0 50px 2px 0;
        color: #7a7b86;
        font-size: 1em;
        line-height: 1.4;
        word-wrap: break-word;
        word-break: break-word;
        
        p + p {
            margin-top: 10px;
        }
    }

    &__info {
        // .chat-message__info
        // Message info (name & date).
        color: $grey-78;
        font-size: 0.9em;
        padding-top: 5px;
    }

    &__name {
        // .chat-message__name
        // Chat message name.
        color: $grey-78;
        font-size: 0.9em;
        padding-top: 5px;
    }

    &__time {
        // .chat-message__time
        // Chat message time.
        font-style: italic;
    }

    &.is-reply {
        // .chat-message.is-reply
        margin-left: 50px;

        .chat-message__bubble {
            // .chat-message.is-reply .chat-message__bubble
            align-self: flex-end;
            background: #408fec;
            border-radius: 20px 20px 0px 20px;
            color: white;
            margin-right: 0;
        }

        .chat-message__info {
            // .chat-message.is-reply .chat-message__info
            text-align: right;
        }
    }

    &.is-typing {
        // chat-message.is-typing
        // Message type used to display animated 'is typing' spinner.

        .chat-spinner__dot:nth-child(1) {
            animation: 1s dot infinite 0.3333s;
        }

        .chat-spinner__dot:nth-child(2) {
            animation: 1s dot infinite 0.6666s;
        }

        .chat-spinner__dot:nth-child(3) {
            animation: 1s dot infinite 0.9999s;
        }
    }
}

.chat-spinner {
    // User is typing animated spinner.
    width: 70px;

    .chat-message__bubble {
        // .chat-spinner .chat-message__bubble
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: 0;
    }

    .chat-message__name {
        // .chat-spinner .chat-message__name
        white-space: nowrap;
    }

    &__dot {
        // .chat-spinner__dot
        // ellipsis dot.
        height: 8px;
        width: 8px;
        margin: 0 2px;
        background-color: $grey-60;
        border-radius: 50%;
        animation: none;
        transition: all .3s ease;
    }
}

//------------------------------------------------------------------------------
// Chat Notification.
//-----

.chat-notification {
    // Text notification message delivered in chat log (e.g. 'Brian has ended this chat.')
    display: inline-flex;
    margin: 0 auto 25px;
    border: 1px solid $grey-92;
    padding: 8px 12px;
    border-radius: 10px;
    font-size: 13px;
    color: $grey-53;

    &:last-child {
        // .chat-notification:last-child
        margin-bottom: 0;
    }

    &__time {
        // .chat-notification__time
        display: inline-block;
        font-style: italic;
        opacity: 0.5;
        margin-left: 5px;
    }
}



//==============================================================================
// Chat Reply Input Field.
//==============================================================================

.chat-reply-field {
    // Footer of chat dialog containing input field, attachment & send buttons.
    height: 45px;
    margin: 20px;
    background: white;
    position: relative;

    &__input {
        // .chat-reply-field__input
        // Input field for sending messages.
        -ms-overflow-style: none;
        width: 100%;
        height: 100%;
        position: relative;
        padding: 13px 85px 13px 10px;
        resize: none;
        border-radius: 5px;
        border: 1px solid $grey-78;

        &::-webkit-input-placeholder {
            // .chat-reply-field__input
            color: $grey-87;
        }

        &::placeholder {
            // .chat-reply-field__input
            color: $grey-87;
        }

        &::-webkit-scrollbar {
            // .chat-reply-field__input
            display: none;
        }
    }

    &__controls {
        // .chat-reply-field__controls
        display: flex;
        position: absolute;
        right: 10px;
        top: 0px;
        bottom: 0;
        height: 30px;
        margin: auto;
    }

    &__button {
        // .chat-reply-field__button
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        border: none;
        border-radius: 50%;
        font-size: 1.5em;
        text-align: center;
        cursor: pointer;
        margin-left: 8px;

        &:hover {
            // .chat-reply-field__button:hover

            .chat-icon {
                // .chat-reply-field__button:hover .chat-icon

                use {
                    // .chat-reply-field__button:hover .chat-icon use
                    fill: $chat-blue;
                }
            }
        }
    }
}


//==============================================================================
// End Chat.
//
// 'End this chat?' (Yes/No) modal.
//==============================================================================

.end-chat-modal {
    // 'End this chat?' (Yes/No) modal.
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: rgba(white, 0.9);
    border-radius: 10px 10px 0 0;

    &.is-hidden {
        // .end-chat-modal.is-hidden
        // Hidden state.
        display: none
    }

    &__content {
        // .end-chat-modal__content
        font-size: 20px;
        max-width: 200px;
        width: 100%;
    }
}


//==============================================================================
// Drag & Drop.
//
// Modal shown when a file attachment is dragged over the chat dialog.
//==============================================================================

.drag-drop-modal {
    // Modal shown when a file attachment is dragged over the chat dialog.
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    background: rgba(0, 0, 0, 0.6);
    padding: 15px;
    z-index: 5;

    &.is-hidden {
        // .drag-drop-modal.is-hidden
        // Default, hidden state.
        display: none;
    }

    &__content {
        // .drag-drop-modal__content
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        border: 5px dashed rgba(255, 255, 255, 0.5);
        font-size: 1.5em;
        color: white;
        width: 100%;
        height: 100%;

        &.is-hidden {
            // .drag-drop-modal__content.is-hidden
            display: none;
        }
    }
}

//==============================================================================
// File attachments.
//
//==============================================================================

.file {
    // File attachment displayed inside a chat bubble.
    display: inline-flex;
    align-items: center;
    flex: 1;
    border-radius: 5px;
    margin-bottom: 5px;
    background-color: white;
    text-decoration: none;
    color: #777;
    padding: 5px 15px 5px 5px;
    user-select: none;

    &:hover {
        // .file:hover
        background-color: #fafafa;
    }

    svg {
        // .file svg
        // Attachment file icon.
        flex-shrink: 0;
        margin-right: 5px;
        pointer-events: none;
    }

    &__text {
        // .file__text
        // Attachment text.
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
    }

    &__name {
        // .file__name
        // Attachment filename.
        flex: 1;
        margin-right: 8px;
        max-width: 150px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__size {
        // .file__size
        // Attachment filesize.
        opacity: 0.5;
        font-size: 0.8em;
    }
}

//==============================================================================
// Feedback Form.
//
//==============================================================================

.feedback-form {
    // Chat feedback form.
    display: none;
    padding: 30px 20px 20px 20px;

    &__textarea {
        // .feedback-form__textarea

        .field__input {
            // .feedback-form__textarea .field__input
            resize: none;
            max-height: 80px;
            margin-bottom: 20px;
        }
    }
}

.feedback-radio-group {
    // 'How was your chat experience?' radio field.
    display: flex;
    justify-content: space-around;
    max-width: 250px;
    margin: 30px auto;

    &__option {
        // .feedback-radio-group__option
        // Label surrounding option.
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__input {
        // .feedback-radio-group__input
        // Radio input fields.
        height: auto;
    }

    &__text {
        // .feedback-radio-group__text
        // Text labels.
        padding: 10px 0;
        font-size: 15px;
    }
}

//==============================================================================
// Chat Greeting.
//
//==============================================================================

.chat-greeting {
    // Chat greeting message container.

    .chat-actions {
        // .chat-greeting .chat-actions
        // Adjust position of close button.
        height: 60px;
    }

    &.is-hidden {
        // .chat-greeting.is-hidden
        // Hidden state.
        display: none;
    }
}