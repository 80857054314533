// ******************************************************************************
//
// Variables
//
// ******************************************************************************

@use "sass:math";

// ******************************************************************************
// Colours

$mla-purple:            #981D97;
$mla-purple-lighten:    #B552A9;
$white:                 #FFFFFF;
$black:                 #000000;
$light-blue:            #CADFF4;

$grey-98: #f9f9fb;
$grey-96: #f3f3f7;
$grey-92: #e7e7ee;
$grey-90: #e1e1ea;
$grey-87: #d8d8e4;
$grey-78: #bdbdd0;
$grey-60: #8989a9;
$grey-53: #757599;
$grey-40: #595973;
$grey-30: #434356;
$grey-23: #353541;
$grey-20: #2e2e38;
$grey-10: #17171c;





$background-color:      $white;
$primary-text-color:    $grey-20;
$primary-link-color:    $mla-purple;
$nav-bar-background:    $black;

$color-positive:        #25B225;
$color-positive-lighten:#00D100;
$color-negative:        #D63636;
$color-neutral:         #ED8F00;

$mla-orange:            #f06934;
$light-grey:            #f6f6f6;
$lighter-grey:          #fafafa;
$grey:                  #e8eaed;
$dark-grey:             #61656b;

// Mega menu colours
$menu-background:       $grey-23;
$menu-border:           #dadae5;
$menu-light-purple-bg:  #ededf2;
$menu-text:             $white;

// Colours used on basket/checkout.
$teal: #16a085;
$blue: #3C96F0;

// Colours used on My Account pages.
$my-account-blue: #263045;
$color-blueberry: #707DF8;
$color-oxford-blue: #3C4659;

// ******************************************************************************
// Fonts

$base-font:            'chevin-pro', sans-serif;


// ******************************************************************************
// Layout

$container-width:   1520px;

$site-width:        1200px;
$menu-height:       60px;
$base-padding:      15px;
$padding-secondary: 10px;
$ribbon-height:     50px;
$nav-bar-height:    60px;

// Mega Menu layout
$menu-item-height:  48px;
$menu-padding:      math.div($menu-item-height, 2);


// ******************************************************************************
// Media Queries

$desktop:           '(min-width: 751px)';
$mobile:            '(max-width: 750px)';

$xs:                '(min-width: 360px)';
$sm:                '(min-width: 415px)';
$md:                '(min-width: 800px)';
$lg:                '(min-width: 950px)';
$xl:                '(min-width: 1050px)';
$xxl:               '(min-width: 1200px)';
$xxxl:              '(min-width: 1560px)';

$less-than-sm:      '(max-width: 414px)';
$less-than-md:      '(max-width: 799px)';
$less-than-lg:      '(max-width: 949px)';
$less-than-xl:      '(max-width: 1049px)';
$less-than-xxl:     '(max-width: 1199px)';
$less-than-xxxl:    '(max-width: 1559px)';


// ******************************************************************************
// Buttons

$button-height: 60px;
$button-primary-color: $mla-purple;
$button-secondary-color: $mla-orange;
$button-edit-color: rgb(63,143,63);
$button-delete-color: rgb(185,44,40);

// ******************************************************************************
// Z-indexes

$z-header:               99;
$z-header-listings:      100;

$z-mega-menu:            120;
$z-mega-menu-desktop:    101;
$z-mega-menu-dropdown:   100;
$z-my-account-nav:        10;

$z-range-hero:             0;
$z-range-hero-info:       -1;
$z-label-new:              1;
$z-catalogue-cover:       20;
$z-catalogue-buttons:     10;

$z-product-tabs-active:    2;
$z-product-tabs-more-nav: 25;

$z-tooltip:              101;
$z-lightbox:             200;
$z-debug:                 99;

// Checkout
$z-checkout-step:              1;
$z-checkout-line:              0;
$z-checkout-step-circle:       1;

$z-cookies-dialog:      300;
$z-live-chat:           300;
$z-modal-background:    400;
